import React, { useState } from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const ModalVisibility = ({ actionModal, openModal, handleSuccess, entity }) => {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Alterar visibilidade do transporte para {entity?.clientVisibility === "HIDE" ? "Visível" : "Oculto"}
                    </h5>
                </div>
            }
        >
            <p className="text-center">Visibilidade atual: {entity?.clientVisibility === "HIDE" ? "Oculto" : "Visível"}</p>

            <label htmlFor="description" className="w-full">
                Motivo:
            </label>
            <input
                type="text"
                className="w-full rounded-md border py-1 px-5 border-C2 h-9"
                value={description}
                onChange={e => {
                    setDescription(e.target.value);
                }}
            />
            {descriptionError && <p className="text-red w-full text-sm">{descriptionError}</p>}

            <button
                className="mb-4 ml-2 bg-red text-white py-2 px-5 rounded-md float-left mt-10"
                onClick={() => actionModal()}
            >
                Cancelar
            </button>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    if (description.length === 0) {
                        setDescriptionError("O motivo é obrigatório");
                        return;
                    }

                    setLoading(true);

                    requests
                        .changeFreightVisibility(entity.identifier, {
                            description: description,
                            visible: entity.clientVisibility === "HIDE",
                        })
                        .then(response => {
                            handleSuccess();
                        })
                        .catch(error => {
                            setLoading(false);
                            toast.error("Ocorreu um erro ao alterar a visibilidade, atualize a página e tente novamente.");
                        });
                }}
            >
                Alterar {loading && <ClipLoader size="13" color="white" />}
            </button>
        </Modal>
    );
};
export default ModalVisibility;
